"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Drawer = void 0;
const drawer_mode_1 = require("../enum/drawer-mode");
class Drawer {
    constructor(config) {
        this.drawer = null;
        this.config = config;
    }
    changeHeader(header) {
        if (!this.drawer) {
            return;
        }
        const wrapper = this.drawer.querySelector('.Wrapper');
        const currentHeader = wrapper === null || wrapper === void 0 ? void 0 : wrapper.querySelector('.Header');
        if (currentHeader) {
            wrapper === null || wrapper === void 0 ? void 0 : wrapper.replaceChild(header, currentHeader);
            header.classList.add('Header');
        }
    }
    changeFooter(footer) {
        if (!this.drawer) {
            return;
        }
        const wrapper = this.drawer.querySelector('.Wrapper');
        const currentFooter = wrapper === null || wrapper === void 0 ? void 0 : wrapper.querySelector('.Footer');
        if (currentFooter) {
            wrapper === null || wrapper === void 0 ? void 0 : wrapper.replaceChild(footer, currentFooter);
            footer.classList.add('Footer');
        }
    }
    changeContent(content) {
        if (!this.drawer) {
            return;
        }
        const wrapper = this.drawer.querySelector('.Wrapper');
        const currentContent = wrapper === null || wrapper === void 0 ? void 0 : wrapper.querySelector('.Content');
        if (currentContent) {
            wrapper === null || wrapper === void 0 ? void 0 : wrapper.replaceChild(content, currentContent);
            content.classList.add('Content');
        }
    }
    mount() {
        this.drawer = this.generateDrawer();
        document.body.appendChild(this.drawer);
    }
    mountFooter(element) {
        if (!this.config.footer) {
            return;
        }
        const { footer } = this.config;
        const footerContainer = document.createElement('div');
        footerContainer.classList.add('Footer');
        footerContainer.appendChild(footer);
        if (this.config.customFooterStyles) {
            footerContainer.style.cssText = this.config.customFooterStyles;
        }
        element.appendChild(footerContainer);
    }
    mountOverlay(element) {
        const overlay = document.createElement('div');
        overlay.classList.add('Overlay');
        overlay.addEventListener('click', () => {
            if (!this.config.canBeClosed) {
                return;
            }
            this.close();
        });
        element.appendChild(overlay);
    }
    mountHeader(element) {
        if (!this.config.header) {
            return;
        }
        const { header } = this.config;
        const headerContainer = document.createElement('div');
        headerContainer.classList.add('Header');
        headerContainer.appendChild(header);
        if (this.config.customHeaderStyles) {
            headerContainer.style.cssText = this.config.customHeaderStyles;
        }
        if (this.config.canBeClosed) {
            const closeButton = this.generateCloseButton();
            headerContainer.appendChild(closeButton);
        }
        element.appendChild(headerContainer);
    }
    generateCloseButton() {
        const closeButton = document.createElement('button');
        closeButton.setAttribute('aria-label', 'Close drawer');
        closeButton.setAttribute('type', 'button');
        closeButton.classList.add('CloseButton');
        closeButton.addEventListener('click', () => this.close());
        const closeIcon = document.createElement('i');
        closeIcon.classList.add('lnr-cross');
        closeIcon.classList.add('CloseIcon');
        closeButton.appendChild(closeIcon);
        return closeButton;
    }
    generateDrawer() {
        const { mode, content } = this.config;
        const drawer = document.createElement('div');
        drawer.classList.add('Drawer', `Drawer--${mode}`);
        this.mountOverlay(drawer);
        const wrapper = this.generateWrapper();
        drawer.appendChild(wrapper);
        this.mountHeader(wrapper);
        content.classList.add('Content');
        wrapper.appendChild(content);
        this.mountFooter(wrapper);
        return drawer;
    }
    generateWrapper() {
        const wrapper = document.createElement('div');
        wrapper.classList.add('Wrapper');
        this.animateOnOpen(wrapper);
        return wrapper;
    }
    animateOnOpen(wrapper) {
        wrapper.classList.add('Wrapper--is-opening');
        wrapper.addEventListener('animationend', () => {
            wrapper.classList.remove('Wrapper--is-opening');
        });
    }
    switchMode(mode) {
        if (!this.drawer) {
            return;
        }
        this.drawer.classList.remove(`Drawer--${this.config.mode}`);
        this.drawer.classList.add(`Drawer--${mode}`);
        const previousMode = this.config.mode;
        this.config.mode = mode;
        const wrapper = this.drawer.querySelector('.Wrapper');
        switch (previousMode) {
            case drawer_mode_1.DrawerMode.FULL:
                wrapper === null || wrapper === void 0 ? void 0 : wrapper.classList.add('Wrapper--full-to-right');
                break;
            case drawer_mode_1.DrawerMode.RIGHT:
                wrapper === null || wrapper === void 0 ? void 0 : wrapper.classList.add('Wrapper--right-to-full');
                break;
            default:
                break;
        }
        setTimeout(() => {
            wrapper === null || wrapper === void 0 ? void 0 : wrapper.classList.remove('Wrapper--full-to-right');
            wrapper === null || wrapper === void 0 ? void 0 : wrapper.classList.remove('Wrapper--right-to-full');
        }, 400);
    }
    open() {
        if (!this.drawer) {
            this.mount();
        }
        else {
            this.drawer.style.display = 'block';
            const wrapper = this.drawer.querySelector('.Wrapper');
            this.animateOnOpen(wrapper);
        }
        this.disableScroll();
        if (!this.config.onOpen) {
            return;
        }
        this.config.onOpen();
    }
    disableScroll() {
        document.body.style.overflow = 'hidden';
    }
    enableScroll() {
        document.body.style.overflow = 'auto';
    }
    close() {
        this.enableScroll();
        if (!this.drawer) {
            return;
        }
        const wrapper = this.drawer.querySelector('.Wrapper');
        wrapper === null || wrapper === void 0 ? void 0 : wrapper.classList.add('Wrapper--is-closing');
        setTimeout(() => {
            wrapper === null || wrapper === void 0 ? void 0 : wrapper.classList.remove('Wrapper--is-closing');
            this.destroy();
        }, 400);
    }
    destroy() {
        if (!this.drawer) {
            return;
        }
        if (this.config.destroyOnClose) {
            this.drawer.remove();
            this.drawer = null;
        }
        else {
            this.drawer.style.display = 'none';
        }
        if (!this.config.onClose) {
            return;
        }
        this.config.onClose();
    }
}
exports.Drawer = Drawer;
